<template>
    <div>
         <b-row>
          <b-col cols="12">
              <b-img
        :src="imageUrl"
        fluid
      />
         <h4 class="position-absolute" style="left: 20px;top: 10px;">
        <b-badge style="background:#8CE0B8" class="badgeCrown"><span style="color:#E87975;">-{{itembuy.price}}</span>
          <img src="@/assets/images/illustration/crown.svg" style="margin-top:-10px;"/> 
          </b-badge></h4>
          <div class="text-center" style="margin-top:-20px;">
                 <h3>
        <b-badge style="background:linear-gradient(87.2deg, rgba(164, 136, 255, 0.8) 2.33%, rgba(74, 44, 174, 0.83) 51.02%, #27088E 94.9%);border-radius:48px;padding:10px;"
         ><span>{{itembuy.title}}</span>
          </b-badge></h3>
          </div>
       
        </b-col>
    </b-row>
       <div class="text-center mt-2">
            <b-card-title>Thank you for Purchasing {{itembuy.title}}</b-card-title>
              <b-card-sub-title v-html="itembuy.description"></b-card-sub-title>
      </div>
    </div>
</template>
<script>
import {BRow,BCol,BBadge,BCardTitle,BCardSubTitle,BImg} from "bootstrap-vue"
export default {
    props:["itembuy","imageUrl"],
    components:{BRow,BCol,BBadge,BCardTitle,BCardSubTitle,BImg}
}
</script>