<template>
  <div>
    <b-row class="match-height">
      <b-col xl="3" lg="4" md="6" sm="12" v-for="(ite, i) in item" :key="i">
        <b-card no-body class="cardStore" style="background: #181c21">
          <b-overlay
            :show="loading && itembuy.id == ite._id"
            opacity="1.00"
            variant="transparent"
          >
            <template v-slot:overlay>
              <div class="text-success text-center">
                <b-spinner style="width: 3rem; height: 3rem" />
              </div>
              <div
                class="mb-3 mt-1 font-weight-bolder text-success text-center"
              >
                loading...
              </div>
            </template>
            <b-img
              :src="ite.imageUrl"
              alt="Card image cap"
              class="card-img-top"
              fluid
            />
            <b-card-body>
              <div class="meetup-header text-center">
                <b-card-title class="mb-0">
                  {{ $t("curlang") == "en" ? ite.title : ite.titlees }}
                </b-card-title>
              </div>
              <b-row class="mt-1">
                <b-col cols="6">
                  <b-card-text class="mb-0 font-weight-bolder">
                    {{ $t("storeTab.stock") }}
                  </b-card-text>
                </b-col>
                <b-col cols="6">
                  <div class="text-right">
                    <b-card-text class="mb-0 font-weight-bolder text-white">
                      {{ numberWithCommas(ite.stock) }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="6">
                  <b-card-text class="mb-0 font-weight-bolder">
                    {{ $t("storeTab.price") }}
                  </b-card-text>
                </b-col>
                <b-col cols="6">
                  <div class="text-right">
                    <b-card-text class="font-weight-bolder text-white">
                      <b-img
                        :src="ite.typeCoin.value==1?require('@/assets/images/illustration/crown.svg'):require('@/assets/images/illustration/goblet.svg')"
                        style="
                          width: 15.69px;
                          height: 13.22px;
                          margin-top: -5px;
                        "
                      ></b-img>
                      {{ numberWithCommas(ite.price) }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
              <b-button
                class="backgroundBtn mt-2"
                pill
                style="padding: 18px 80px"
                block
                @click="openBuy(ite)"
              >
                {{ $t("storeTab.buy") }}
              </b-button>
            </b-card-body>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <!--Modal Item Details-->
    <b-modal
      ref="modal-item"
      v-model="showmodal"
      title="Buy Detail"
      hide-footer
      :no-close-on-backdrop="loadingBuy"
      :hide-header-close="loadingBuy"
    >
      <b-overlay :show="loadingBuy" opacity="1.00" variant="transparent">
        <template v-slot:overlay>
          <div class="text-success text-center">
            <b-spinner style="width: 3rem; height: 3rem" />
          </div>
          <div class="mb-3 mt-1 font-weight-bolder text-success text-center">
            Processing the purchase...
          </div>
        </template>
        <item-available-details :itembuy="itembuy" :exp="exp" />
        <b-button
          :class="itembuy.typeCoin==1?
            exp.crowns < itembuy.price
              ? 'backgroundBtnNE mt-2 mb-2'
              : 'backgroundBtn mt-2 mb-2'
              :
               exp.goblets < itembuy.price
              ? 'backgroundBtnNE mt-2 mb-2'
              : 'backgroundBtn mt-2 mb-2'
          "
          :disabled="itembuy.typeCoin==1?exp.crowns < itembuy.price ? true : false:exp.goblets < itembuy.price ? true : false"
          block
          @click="itemAccept"
          pill
        >
          {{ btnText() }}
        </b-button>
      </b-overlay>
    </b-modal>
    <!--Modal Item Details-->
    <b-modal
      ref="modal-gift"
      v-model="showmodalGift"
      hide-header
      hide-header-close
      hide-footer
      no-close-on-backdrop
      centered
    >
      <item-available-purchased :itembuy="itembuy" :imageUrl="imageUrl" />
    </b-modal>

    <b-modal
      title="Mystery Box"
      v-model="showmodalMB"
      hide-header-close
      hide-footer
      no-close-on-backdrop
    >
      <mystery-box :itembuy="itembuy" />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BImg,
  BButton,
  BModal,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BBadge,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import MysteryBox from "@/views/elite/store/MysteryBox.vue";
import ItemAvailableDetails from "@/components/ItemAvailableDetails.vue";
import ItemAvailablePurchased from "@/components/ItemAvailablePurchased.vue";
import Ripple from "vue-ripple-directive";
import SellerService from "@/services/SellerService";
const user = JSON.parse(localStorage.getItem("auth"));
export default {
  props: ["item"],
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BImg,
    BButton,
    BModal,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BBadge,
    MysteryBox,
    BSpinner,
    BOverlay,
    ItemAvailableDetails,
    ItemAvailablePurchased,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userL: user,
      showmodal: false,
      showmodalGift: false,
      showmodalMB: false,
      loading: false,
      loadingBuy: false,
      exp: 0,
      imageUrl: null,
      itembuy: {
        especial: {
          invitationwallet:
            user.profile && user.profile.walletinvitation
              ? user.profile.walletinvitation
              : null,
          whitelistwallet:
            user.profile && user.profile.wallettoreceive
              ? user.profile.wallettoreceive
              : null,
          twitteruser:
            user.profile && user.profile.twitteruser
              ? user.profile.twitteruser
              : null,
        },
      },
    };
  },
  mounted() {
    this.getExpbyUser();
    this.getProfiloUser();
  },
  methods: {
    getProfiloUser: async function () {
      const ex = (
        await SellerService.getProfile(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.especial = ex;
      this.userL.profile = ex;
    },
    getExpbyUser: async function () {
      const ex = (
        await SellerService.getExpbyUser(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.exp = ex;
    },

  btnText: function () {
    let x=null
    if (this.itembuy.typeCoin==1) {
        x =
        this.exp.crowns >= this.itembuy.price
          ? this.$t("storeTab.buyitem") + " " + this.itembuy.price + " Crowns"
          : "No Enough Crowns"; 
    }
    if (this.itembuy.typeCoin==2) {
       x =
        this.exp.goblets >= this.itembuy.price
          ? this.$t("storeTab.buyitem") + " " + this.itembuy.price + " Goblets"
          : "No Enough Goblets"; 
    }
     
      return x;

     
    },
    openBuy(i) {
      this.imageUrl = i.imageUrl;
      this.itembuy = {
        id: i._id,
        title: i.title,
        titlees: i.titles,
        price: i.price,
        decription: i.description,
        descriptiones: i.descriptiones,
        startdate: i.startdate,
        stock: i.stock,
        typeCoin:i.typeCoin.value,
        especial: {
          invitationwallet:
            user.profile && user.profile.walletinvitation
              ? user.profile.walletinvitation
              : null,
          whitelistwallet:
            user.profile && user.profile.wallettoreceive
              ? user.profile.wallettoreceive
              : null,
          twitteruser:
            user.profile && user.profile.twitteruser
              ? user.profile.twitteruser
              : null,
          discount: i.type.amount,
          type: i.type.value,
        },
      };

      if (this.userL.profile) {
        if (
          this.itembuy.especial.type != 10 &&
          this.itembuy.especial.type != 11
        ) {
          this.showmodal = true;
        }
      }

      if (this.userL.profile && this.itembuy.especial.type == 10) {
        let dataRuffle = { item: this.itembuy, image: this.imageUrl,haveCrowns: this.itembuy.typeCoin==1?this.exp.crowns >= this.itembuy.price:this.exp.goblets >= this.itembuy.price };
        localStorage.setItem("dataruffle", null);
        localStorage.removeItem("dataruffle");
        localStorage.setItem("dataruffle", JSON.stringify(dataRuffle));
        this.$router.push({ name: "ruffle" });
      }
      if (this.userL.profile && this.itembuy.especial.type == 11) {
         
        if(this.itembuy.typeCoin==1){
          if (this.exp.crowns>= this.itembuy.price) {
          this.$swal({
            title: "Are you sure?",
            text: "To purchase this item",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Buy",
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.loading = true;
              const vm = this;
              setTimeout(function () {
                vm.loading = false;
                vm.showmodalMB = true;
              }, 5000);
            }
          });
        } else {
          this.$swal({
            title: "",
            text: "You don't have enough crowns",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
        }else if (this.itembuy.typeCoin==2){
        if (this.exp.goblets>= this.itembuy.price) {
          this.$swal({
            title: "Are you sure?",
            text: "To purchase this item",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Buy",
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.loading = true;
              const vm = this;
              setTimeout(function () {
                vm.loading = false;
                vm.showmodalMB = true;
              }, 5000);
            }
          });
        } else {
          this.$swal({
            title: "",
            text: "You don't have enough goblets",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        }
      }
      }

      if (!this.userL.profile) {
        this.$swal({
          title: "Warning",
          text: "Go to profile and fill out the information",
          icon: "warning",
          confirmButtonText: "Go to Profile",
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-warning",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            this.$router.replace({ path: "/profile" });
          }
        });
      }
    },
    itemAccept: async function () {
      this.loadingBuy = true;
      if (this.itembuy.especial.type != 7 && this.itembuy.especial.type != 8) {
        setTimeout(() => {
          this.loadingBuy = false;
          this.$refs["modal-item"].hide();
          this.showmodalGift = true;
        }, 5000);

        setTimeout(() => {
          this.$refs["modal-gift"].hide();
          this.$router.go(0);
        }, 10000);
        const d = (
          await SellerService.InsertBuyItem(
            JSON.parse(localStorage.getItem("auth")).discordId,
            this.itembuy
          )
        ).data;
      }
      if (this.itembuy.especial.type == 7) {
        setTimeout(() => {
          this.loadingBuy = false;
          this.$refs["modal-item"].hide();
          this.showmodalGift = true;
        }, 5000);

        setTimeout(() => {
          this.$refs["modal-gift"].hide();
          this.$router.go(0);
        }, 10000);
        const d = (
          await SellerService.InsertBuyItem(
            JSON.parse(localStorage.getItem("auth")).discordId,
            this.itembuy
          )
        ).data;
      }
    },
    numberWithCommas: function (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.backgroundBtn {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
  border: transparent;
  padding: 18px 80px;
}
.backgroundBtnNE {
  background: #828282;
  border: transparent;
  border: transparent;
  padding: 18px 80px;
}
.badgeCrown {
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 4px;
  border-radius: 48px;
}
.cardStore {
  overflow: hidden;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px #82868b !important;
  }
}
</style>
