<template>
  <div>
    <b-card class="overflow-hidden bgcCustom h-100" v-if="items.length > 0">
      <b-row class="match-height">
        <b-col
          v-for="(item, i) in items"
          :key="i"
          xl="4"
          lg="4"
          md="6"
          sm="12"
          class="mb-2"
        >
          <b-card
            class="cardStore cursor-pointer"
            overlay
            :style="
              'background:linear-gradient(1.04deg, #000000 2.47%, rgba(0, 0, 0, 0) 132.98%), url(' +
              item.imagenUrl +
              '); height:280px;border-radius:20px;'
            "
            img-alt=""
            text-variant="white"
            @click="purshasedDetail(item)"
          >
            <div class="position-absolute badgeCrown">
              <b-img
                :src="purchasedImage(item)"
                fluid
                alt=""
                style="padding: 4px 4px"
              ></b-img>
              {{ item.price }}
            </div>

            <b-card-text class="position-absolute purchaseTitle">
              {{ item.title }}
            </b-card-text>
            <b-card-text class="position-absolute badgeW" v-if="item.winner">
              <b-img
                :src="require('@/assets/images/illustration/crown.svg')"
                fluid
                alt=""
                style="width: 18px; height: 18px; margin-top: -5px"
              />
              {{ item.winner }}
            </b-card-text>
            <b-card-text class="position-absolute timeago">
              {{ item.stages[0].shortDate }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else style="min-height: 60vh">
      <section class="h-100" style="min-height: 60vh">
        <header class="container h-100" style="min-height: 60vh">
          <div
            class="d-flex align-items-center justify-content-center h-100"
            style="min-height: 60vh"
          >
            <div class="d-flex flex-column">
              <h4 class="text align-self-center p-1">No items purchased yet</h4>
              <h6 class="text align-self-center p-2">
                Use your crown to purchase items from the store .
              </h6>
            </div>
          </div>
        </header>
      </section>
    </b-card>
    <b-modal
      v-model="openmodal"
      :title="$t('curlang') == 'en' ? 'Timeline' : 'Linea de Tiempo'"
      hide-footer
      centered
    >
      <app-timeline class="mb-1 mt-1">
        <app-timeline-item
          v-for="(stage, i) in stages"
          :key="i"
          :title="stage.stageName"
          :subtitle="
            $t('curlang') == 'en'
              ? 'Your product have been ' + stage.stageName
              : 'Tu compra realizada está ' + stage.stageName
          "
          :time="stage.shortDate"
          :variant="variantTimeline(stage.stageName)"
        />
      </app-timeline>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BModal,
  BImg,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import SellerService from "@/services/SellerService";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BCardText,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BImg,
  },
  data() {
    return {
      items: [],
      openmodal: false,
      stages: [],
    };
  },
  mounted() {
    this.getItempurchased();
  },
  methods: {

    purchasedImage:function(item){
    try{
    return item["typeCoin"]["value"]==1?require('@/assets/images/illustration/crown.svg'):require('@/assets/images/illustration/goblet.svg')

    }catch( e){
    return require('@/assets/images/illustration/crown.svg')
    }
     return require('@/assets/images/illustration/crown.svg')
    },
    getItempurchased: async function () {
      const gip = (
        await SellerService.getItempurchased(
          JSON.parse(localStorage.getItem("auth")).discordId
        )
      ).data;
      this.items = gip;
    },
    purshasedDetail: function (item) {
      this.stages = [];
      this.openmodal = true;
      this.stages = item.stages;
    },
    variantTimeline(n) {
      if (n == "pending") {
        return "danger";
      }
      if (n == "approved") {
        return "warning";
      }
      if (n == "delivered") {
        return "success";
      }
      if (n == "rejected") {
        return "secondary";
      }
      if (n == "pendiente") {
        return "danger";
      }
      if (n == "aprobada") {
        return "warning";
      }
      if (n == "entregada") {
        return "success";
      }
      if (n == "rechazada") {
        return "secondary";
      }
    },
  },
};
</script>

<style lang="scss">
.bgcCustom {
  border-radius: 8px;
}
.purchaseTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #ffffff;
  left: 10px;
  bottom: 15px;
  width: 255px;
}

.badgeCrown {
  display: flex;
  width: 93.69px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #223433;
  border-radius: 4px;
  top: 5%;
  right: 5%;
  bottom: 20.31%;
  margin-top: 3.45%;
}

.badgeW {
  display: flex;
  width: 150px;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: #223433;
  border-radius: 4px;
  margin-top: 3.45%;
  top: 5%;
}

.timeago {
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.015em;
  color: #9f9f9f;
  left: 2%;
  bottom: -10%;
}
.cardStore {
  overflow: visible;
}

.cardStore:hover {
  overflow: hidden;
  transform: scale(1.05);
  box-shadow: 0px 0px 10px #82868b !important;
}
</style>